import { graphql } from "gatsby"
import React from "react"
import { ArtProps } from "./art.d"
import tw from "twin.macro"
import { BackgroundContainer } from "../../components/BackgroundContainer"
import ImageHero from "../../components/Hero/ImageHero"
import PageHeading from "../../components/PageHeading/PageHeading"
import ArtCard from "../../components/Art/ArtCard"
import { SEO } from "../../components/SEO"

const Art: React.FC<ArtProps> = ({ data, location }) => {
  const { title, content, featuredImage, template } = data.wpPage
  const heroImage =
    featuredImage.node?.localFile?.childImageSharp?.gatsbyImageData
  const { artAtBhmPage } = template

  return (
    <div className="page-content">
      <SEO url={location.href} title={title} />
      {heroImage && <ImageHero image={featuredImage} />}

      {artAtBhmPage?.artistCredit && (
        <span
          css={tw`flex flex-col items-baseline pr-8 pt-4 max-w-[250px] ml-auto text-sm`}
        >
          {artAtBhmPage.artistCredit.map(
            (credit: { creditText: string }, i: number) => (
              <p key={i}>{credit.creditText}</p>
            )
          )}
        </span>
      )}

      <BackgroundContainer url="url('/airport-interior.png')">
        <PageHeading heading={title} content={content} animate={true} />
        <section
          css={tw`container mx-auto pt-20 px-2 flex flex-col flex-wrap sm:flex-row sm:justify-between 2xl:max-w-[1280px]`}
        >
          {artAtBhmPage.artExhibits?.map(
            (
              {
                title,
                mapLocation,
                artistDetails,
                artistLifespan,
                summary,
                description,
                thumbnailImage,
                image,
              },
              i
            ) => {
              return (
                <ArtCard
                  key={i}
                  header={title}
                  subheading={artistDetails}
                  highlightedText={artistLifespan}
                  mapLocation={mapLocation}
                  summary={summary}
                  description={description}
                  thumbnailImage={thumbnailImage}
                  image={image}
                  css={[tw`mx-auto lg:my-8 xl:mx-2`]}
                />
              )
            }
          )}
        </section>
      </BackgroundContainer>
    </div>
  )
}

export const query = graphql`
  query ($id: String!) {
    wpPage(id: { eq: $id }) {
      title
      content
      featuredImage {
        node {
          localFile {
            publicURL
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
      template {
        ... on WpTemplate_Art {
          templateName
          artAtBhmPage {
            artistCredit {
              creditText
            }
            artExhibits {
              title
              mapLocation {
                label
                identifier
              }
              artistDetails
              artistLifespan
              summary
              description
              thumbnailImage {
                altText
                localFile {
                  publicURL
                  childImageSharp {
                    gatsbyImageData
                  }
                }
              }
              image {
                altText
                localFile {
                  publicURL
                  childImageSharp {
                    gatsbyImageData
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

export default Art
