import React, { useContext, useEffect, useState } from "react"
import tw from "twin.macro"
import { Image } from "../Image"
import Link from "../Link/Link"
import { H3 } from "../Typography"
import { ArtCardProps } from "./ArtCard.d"
import { Modal } from "../Modal"
import TranslateClient from "../../clients/TranslateClient"
import { LanguageContext } from "../../context/Language"
const { getStringValuesArray, mapValuesToObject } = require("../../helpers")

const ArtCard: React.FC<ArtCardProps> = ({
  header,
  subheading,
  highlightedText,
  mapLocation,
  summary,
  description,
  thumbnailImage,
  image,
  ...remainingProps
}) => {
  const [openModal, setOpenModal] = useState(false)
  const { language } = useContext(LanguageContext)
  const { label, identifier } = mapLocation
  const imagePlaceholderText = "YET TO BE PHOTOGRAPHED"
  const locationText = "Location"
  const [cardContent, setCardContent] = useState({
    header,
    subheading,
    highlightedText,
    imagePlaceholderText,
    locationText,
    label,
    summary,
    description,
  })

  useEffect(() => {
    if (language === "en") {
      setCardContent({
        header,
        subheading,
        highlightedText,
        imagePlaceholderText,
        locationText,
        label,
        summary,
        description,
      })
    } else {
      ;(async () => {
        const textToTranslate: string[] = getStringValuesArray([
          header,
          subheading,
          highlightedText,
          imagePlaceholderText,
          locationText,
          label,
          summary,
          description,
        ])
        const translations = await TranslateClient.translate(
          textToTranslate,
          language
        )
        const translated = mapValuesToObject(cardContent, translations)
        setCardContent(translated)
      })()
    }
  }, [language])

  if (!cardContent) return null

  return (
    <>
      <article
        css={tw`h-auto w-full bg-lightGray max-w-[24rem] overflow-hidden mb-4 cursor-pointer sm:min-h-[30rem]`}
        onClick={() => setOpenModal(!openModal)}
        {...remainingProps}
      >
        {thumbnailImage ? (
          <Image
            src={thumbnailImage?.localFile?.childImageSharp?.gatsbyImageData}
            publicURL={thumbnailImage?.localFile?.publicURL}
            alt={thumbnailImage?.altText}
            css={tw`w-full h-64`}
          />
        ) : (
          <div
            css={tw`flex h-64 w-full items-center	justify-center text-center bg-extraLightGray`}
          >
            <p>{cardContent.imagePlaceholderText}</p>
          </div>
        )}
        <div css={tw`py-8 px-4`}>
          <H3>{cardContent.header}</H3>
          {mapLocation.label && (
            <div>
              <span css={tw`font-extrabold`}>{cardContent.locationText}: </span>
              <Link
                css={[tw`font-extrabold`]}
                to={`/terminal-maps?location=${identifier}`}
              >
                {cardContent.label}
              </Link>
            </div>
          )}
          {highlightedText ? (
            <p css={tw`mb-4`}>
              {cardContent.subheading},{" "}
              <span css={tw`italic`}>{cardContent.highlightedText}</span>
            </p>
          ) : (
            <p css={tw`mb-4`}>{cardContent.subheading}</p>
          )}
          <p dangerouslySetInnerHTML={{ __html: cardContent.summary }}></p>
        </div>
      </article>

      <Modal
        open={openModal}
        onClose={() => setOpenModal(!openModal)}
        css={tw`py-10 md:px-8 lg:py-8 lg:px-12 sm:w-9/12 z-20`}
      >
        <div>
          {image ? (
            <Image
              src={image?.localFile?.childImageSharp?.gatsbyImageData}
              publicURL={image?.localFile?.publicURL}
              alt={image?.altText}
              css={tw`w-full max-h-[30rem] object-contain`}
            />
          ) : (
            <div
              css={tw`flex h-64 w-full items-center	justify-center text-center bg-extraLightGray`}
            >
              <p>{cardContent.imagePlaceholderText}</p>
            </div>
          )}
        </div>
        <div
          css={[
            tw`py-8 flex flex-col md:flex-row md:px-4`,
            description && tw`md:flex-row`,
          ]}
        >
          <div css={[description && tw`md:w-1/3`]}>
            <H3>{cardContent.header}</H3>
            {cardContent.label && (
              <div>
                <span css={tw`font-bold`}>{cardContent.locationText}: </span>
                <Link
                  css={[tw`font-extrabold`]}
                  to={`/terminal-maps?location=${identifier}`}
                >
                  {cardContent.label}
                </Link>
              </div>
            )}
            {highlightedText ? (
              <p>
                {cardContent.subheading},{" "}
                <span>{cardContent.highlightedText}</span>
              </p>
            ) : (
              <p>{cardContent.subheading}</p>
            )}
          </div>
          {description && (
            <p
              css={tw`block md:w-2/3 md:pl-6 mt-4 md:mt-0`}
              dangerouslySetInnerHTML={{ __html: cardContent.description }}
            ></p>
          )}
        </div>
      </Modal>
    </>
  )
}

export default ArtCard
